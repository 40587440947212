import React, {forwardRef} from 'react';

import s from './BlinkingText.module.scss'

type BlinkingText = {
    title: string
    children: React.ReactNode
    open: boolean
    onClose?: (open: boolean) => void
}

export const BlinkingText = forwardRef<HTMLDivElement, BlinkingText>((props, ref) => {

    const onCloseHandler = () => {
        if (props.onClose) {
            props.onClose(props.open)
        }
    }

    return (
        <>
            {props.open && <div ref={ref} onClick={onCloseHandler} className={s.iconsMouse} {...props}>
                {props.children}
                <h3 className={s.iconText}>{props.title}</h3>
            </div>}
        </>
    );
})
