import style from './Main.module.scss'
import {ReactTypingEffectText} from "../common/componets/typingText/ReactTypingEffect";
import React, {useEffect, useState} from 'react'
import {WaveAnimation} from "../common/componets/WaveAnimation/WaveAnimation";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import {BlinkingText} from "../common/componets/BlinkingText/BlinkingText";



export const Main = () => {

    const [openBlinkingText, setOpenBlinkingText] = useState(true)

    useEffect(() => {
        setTimeout(()=>{
            setOpenBlinkingText(false)
        },5000)
    }, [openBlinkingText]);


    const textTyping = ["React developer.", "Frontend developer."]



    return (
        <>
            <WaveAnimation className={style.fluidAnimation} />
            <BlinkingText open={openBlinkingText} title={'Нажмите на мышь подвигайте по экрану!'}>
               <AdsClickIcon sx={{color:'#fff'}}/>
            </BlinkingText>
            <div className={style.main}>

                <div className={style.container}>
                    <div className={style.containerCenter}>
                        <div className={style.textCenter}>
                            <h1 className={style.name}>
                                Roman Rybkin
                            </h1>
                            <h4 className={style.headLine}>
                                {/*Камила!*/}
                                <span className={style.singleHeadLine}>
                                <ReactTypingEffectText
                                    text={textTyping}
                                    speed={200}
                                    eraseSpeed={200}
                                    eraseDelay={5000}
                                    typingDelay={500}
                                />
                            </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};